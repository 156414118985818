<form (submit)="onSubmit($event)" [formGroup]="form">
    <div class="form-row">
        <label for="code">Chave de acesso</label>
        <input class="control" type="text" placeholder="Informe a chave de acesso do arquivo" name="code" formControlName="code">
        <span class="error">Chave invalida</span>
    </div>
    <!-- Logo -->
    <div class="container d-flex justify-content-center mt-3 logo">
        <a href="http://www.ladder.dev.br">
            <img class="img-logo" src="/assets/img/logo-v.png">
        </a>
    </div>
</form>
