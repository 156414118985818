import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.sass']
})
export class HomeComponent {
    public form: FormGroup;

    constructor(
        private router: Router,
        private fb: FormBuilder
    ) {
        this.form = fb.group({ code: [null, [Validators.required]] });
    }

    public onSubmit(event: Event): void {
        if (this.form.valid) {
            this.router.navigate(['/', this.form.get('code').value]);
        } else {
            this.form.get('code').markAsTouched();
        }
    }
}
