import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-view',
    templateUrl: './view.component.html',
    styleUrls: ['./view.component.sass']
})
export class ViewComponent implements OnInit, OnDestroy {

    private sub: Subscription;

    public title: string;
    public pages: any[];
    public figcaption = false;
    public total_page = 0;
    public error: string;

    constructor(
        private route: ActivatedRoute,
        private http: HttpClient
    ) { }

    public ngOnInit(): void {
        this.sub = this.route.params.subscribe(params => {
            this.title = params['code'];

            const url = `/api/ecm/sign/${params['code']}/pages`

            this.http.get<any[]>(url)
                .subscribe(res => {
                    this.pages = res;
                    this.total_page = this.pages.length;
                    this.figcaption = this.total_page > 1;
                }, err => {
                    if (err.status === 404) {
                        this.error = `Assinatura ${params['code']} não encontrada.`;
                    } else {
                        this.error = err.error || `Houve um erro ao abrir este documento!`;
                    }
                });
        });
    }

    public ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}
