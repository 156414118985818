import { FormGroup, FormArray, FormControlDirective, FormControlName, AbstractControl, FormControl } from '@angular/forms';

export const markAsTouched = (formulario: FormGroup | FormArray): void => {
  // console.log(formulario);
  if (!!formulario) {
    Object.keys(formulario.controls).forEach((key: string) => {
      const abstractControl = formulario.controls[key];

      if (abstractControl instanceof FormGroup || abstractControl instanceof FormArray) {
        markAsTouched(abstractControl);
      } else {
        abstractControl.markAsTouched({ onlySelf: true });
        if (abstractControl.invalid) {
          console.log(`Invalid Control "${key}": `, abstractControl);
        }
      }
    });
  }
};

export const setFocusError = (form: FormGroup | FormArray): boolean => {
  if (!!form) {
    const keys = Object.keys(form.controls);
    for (const key of keys) {
      const abstractControl = form.controls[key];

      if (abstractControl instanceof FormGroup || abstractControl instanceof FormArray) {
        if (setFocusError(abstractControl)) {
          return true;
        }
      } else {
        if (abstractControl.invalid) {
          NativeFocus(abstractControl);
          return true;
        }
      }
    }
  }
  return false;
};

export const getFieldError = (form: FormGroup | FormArray, fieldNames = {}): string[] => {
  const fields = [];
  if (!!form) {
    const keys = Object.keys(form.controls);
    for (const key of keys) {
      const abstractControl = form.controls[key];

      if (abstractControl instanceof FormGroup || abstractControl instanceof FormArray) {
        fields.push( ...getFieldError(abstractControl, fieldNames));
      } else {
        if (abstractControl.invalid) {
          fields.push(key);
        }
      }
    }
  }
  const fieldSet: Set<string> = new Set();
  fields.forEach(f => fieldSet.add(fieldNames[f] ? fieldNames[f] : f));
  return Array.from(fieldSet);
};

export interface OnNativeFocus {
  focus(): void;
}

// ------- ISSO E GAMBI E DAS BRUTAS - NAO TOCAR -------
function nativeFocus(form) {
  return function () {
    if (!form || !form.valueAccessor) {
      return;
    }

    if (form.valueAccessor.focus) {
      form.valueAccessor.focus();
    } if (form.valueAccessor._elementRef && form.valueAccessor._elementRef.nativeElement) {
      form.valueAccessor._elementRef.nativeElement.focus();
    }
  };
}

const __FormControlDirectiveOnChanges__ = FormControlDirective.prototype.ngOnChanges;
FormControlDirective.prototype.ngOnChanges = function () {
  const result = __FormControlDirectiveOnChanges__.apply(this, arguments);
  this.form.nativeFocus = nativeFocus(this);
  return result;
};

const __FormControlNameOnChanges__ = FormControlName.prototype.ngOnChanges;
FormControlName.prototype.ngOnChanges = function () {
  const result = __FormControlNameOnChanges__.apply(this, arguments);
  this.control.nativeFocus = nativeFocus(this);
  return result;
};

export const NativeFocus = (control: any, timeout: number = 50) => {
  setTimeout(() => {
    if (control && control.nativeFocus) {
      control.nativeFocus();
    }
  }, timeout);
};
// ------- FIM DA GAMBI --------------------------------


export function cloneAbstractControl<T extends AbstractControl>(control: T): T {
  let newControl: T;

  if (control instanceof FormGroup) {
    const formGroup = new FormGroup({}, control.validator, control.asyncValidator);
    const controls = control.controls;

    Object.keys(controls).forEach(key => {
      formGroup.addControl(key, cloneAbstractControl(controls[key]));
    })

    newControl = formGroup as any;
  }
  else if (control instanceof FormArray) {
    const formArray = new FormArray([], control.validator, control.asyncValidator);

    control.controls.forEach(formControl => formArray.push(cloneAbstractControl(formControl)))

    newControl = formArray as any;
  }
  else if (control instanceof FormControl) {
    newControl = new FormControl(control.value, control.validator, control.asyncValidator) as any;
  }
  else {
    throw new Error('Error: unexpected control value');
  }

  if (control.disabled) newControl.disable({emitEvent: false});

  return newControl;
}
