import { Component, NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

// Modules
import { CoreModule } from 'src/app/core/core.module';

import { ValidaArquivoRoutingModule } from './valida-arquivo.routing';
import { HomeComponent } from './home/home.component';
import { ViewComponent } from './view/view.component';

registerLocaleData(localePt);

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'body',
    template: '<router-outlet></router-outlet>'
})
export class CheckFileComponent { }

@NgModule({
    imports: [
        ValidaArquivoRoutingModule,
        CoreModule,
        ReactiveFormsModule
    ],
    declarations: [
        CheckFileComponent,
        HomeComponent,
        ViewComponent
    ],
    bootstrap: [CheckFileComponent]
})
export class ValidaArquivoModule { }
